import { includes, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';

import { POST_ACTION_SHARE } from '@wix/communities-blog-client-common/dist/src/constants/post-actions';
import { SECTION_HOMEPAGE } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import PostContent from '../post-content';
import CategoryLabelList from '../category-label-list';
import PostPageMetadata from '../post-page-metadata';
import PostTitle from '../post-title';
import PostMainActions from '../post-main-actions';
import PostActions from '../post-actions';
import PostHeaderIcons from '../post-header-icons';
import { MoreButton } from '../more-button';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPostBorderWidth from '../../hoc/with-post-border-width';
import withTranslate from '../../hoc/with-translate';
import withPermissions from '../../hoc/with-permissions';
// import Wix from '../../services/wix-sdk-polyfill';  //HACK
import { getIsDesktop } from '../../store/basic-params/basic-params-selectors';
import { getSection } from '../../selectors/section-selectors';
import { getLayoutName } from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { getLayoutType } from '../../selectors/layout-selectors';
import {
  getIsCategoryLabelsEnabled,
  getIsMoreButtonEnabled,
  getIsTagsEnabled,
} from '../../selectors/app-settings-selectors';
import { getPostActions } from '../../services/post-actions';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsPostInPreview } from '../../selectors/is-post-in-preview';
import { isEditor } from '../../selectors/view-mode-selectors';
import PostUpdatedDate from './post-updated-date';
import Tags from '../tags';
import styles from './post.scss';

export class Post extends React.Component {
  state = {
    componentVisible: true,
  };

  constructor(props) {
    super(props);

    this.preventTitleFocus = false;
    this.titleRef = React.createRef();
  }

  // showComponent = () => this.setState({ componentVisible: true });  //HACK
  // hideComponent = () => this.setState({ componentVisible: false });

  componentDidMount() {
    this.focusOnTitle();

    // Wix.addEventListener(Wix.Events.PAGE_NAVIGATION_IN, this.showComponent);
    // Wix.addEventListener(Wix.Events.PAGE_NAVIGATION_OUT, this.hideComponent);
  }

  componentDidUpdate(/* prevProps, prevState*/) {
    // const { componentVisible } = this.state;
    //
    // if (prevState.componentVisible !== componentVisible) {
    //   if (componentVisible) {
    //     this.focusOnTitle();
    //   } else {
    //     this.removeTabKeyDownListener();
    //     this.preventTitleFocus = false;
    //   }
    // }
  }

  componentWillUnmount() {
    // this.removeTabKeyDownListener();
    //
    // Wix.removeEventListener(Wix.Events.PAGE_NAVIGATION_IN, this.showComponent);
    // Wix.removeEventListener(Wix.Events.PAGE_NAVIGATION_OUT, this.hideComponent);
  }

  focusOnTitle = () => {
    // const { isEditor, isInPostPage } = this.props;
    //
    // // TODO update after out of iframe, timeout is needed for accessibility audio indication on focus to work
    // if (!isEditor && isInPostPage) {
    //   this.preventTitleFocusOnTabKeyDown();
    //
    //   setTimeout(() => {
    //     if (!this.preventTitleFocus) {
    //       this.titleRef.current && this.titleRef.current.focus();
    //     }
    //     this.removeTabKeyDownListener();
    //   }, 2000);
    // }
  };

  preventTitleFocusOnTabKeyDown = () => {
    // document.addEventListener('keydown', this.tabKeyDownListener);
  };

  removeTabKeyDownListener = () => {
    // document.removeEventListener('keydown', this.tabKeyDownListener);
  };

  // eslint-disable-next-line
  tabKeyDownListener = e => {
    // if (e.keyCode === 9) {
    //   this.preventTitleFocus = true;
    //   this.removeTabKeyDownListener();
    // }
  };

  render() {
    const {
      applyFeedDesign,
      borderWidth,
      contentFontClassName,
      getPostClassName,
      isDesktop,
      isInPostPage,
      isMetadataHeaderVisible,
      isCategoryLabelsEnabled,
      post,
      postLink,
      showMoreButton,
      showShareButtons,
      showPostUpdatedDate,
      showPostTitle,
      titleFontClassName,
      layoutName,
      isMoreButtonEnabled,
      isTagsEnabled,
    } = this.props;

    const containerClassName = classNames(
      styles.container,
      contentFontClassName,
      'blog-card-background-color',
      'blog-card-border-color',
      'blog-text-color',
      { [styles.withShareButtons]: showShareButtons },
      getPostClassName('border-color', 'post-container', 'background-color'),
    );
    const moreButtonId = `more-button-${post._id}`;
    const titleContainerClassName = classNames(
      styles.title,
      !isMetadataHeaderVisible && styles.withoutHeaderMetadata,
      post.isPinned && styles.withIcons,
      !isInPostPage && styles.notPostPage,
      showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
    );

    const titleClassName = classNames(
      applyFeedDesign ? getPostClassName('title-font', 'title-color') : [titleFontClassName],
      'blog-post-title-color',
    );

    const metadataClassName = classNames(applyFeedDesign && getPostClassName('description-font', 'description-color'));
    const postContentClassName = classNames(
      styles.content,
      applyFeedDesign && getPostClassName('description-style-font', 'description-color'),
    );

    const headerRightClass =
      isDesktop && isInPostPage ? classNames(styles.headerRight, styles.postPageDesktopHeader) : styles.headerRight;

    return (
      <article className={containerClassName} style={{ borderWidth }} data-hook="post">
        <div className={styles.contentWrapper}>
          <div className={styles.mobileContainer}>
            <div className={styles.header}>
              {isMetadataHeaderVisible && (
                <PostPageMetadata
                  type={layoutName}
                  post={post}
                  className={metadataClassName}
                  linkClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
                />
              )}
              <div className={headerRightClass}>
                <PostHeaderIcons post={post} />
                {isMoreButtonEnabled && (
                  <MoreButton className={styles.moreButton} id={moreButtonId}>
                    <PostActions post={post} focusOnCloseId={moreButtonId} />
                  </MoreButton>
                )}
              </div>
            </div>
            {showPostTitle && (
              <div ref={this.titleRef} tabIndex="-1" className={titleContainerClassName} data-hook="post-title">
                <PostTitle
                  isInPostPage={isInPostPage}
                  className={titleClassName}
                  type={PostTitle.SINGLE}
                  title={post.title}
                  to={postLink}
                  linkClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
                />
              </div>
            )}
          </div>
          {showPostUpdatedDate && <PostUpdatedDate post={post} />}
          <div className={postContentClassName} data-hook="post-description">
            <PostContent post={post} />
          </div>
          <div className={styles.mobileContainer}>
            {isTagsEnabled && <Tags isPostPageTags={true} tags={post.tags} />}
            {isCategoryLabelsEnabled && !isDesktop && (
              <div className={styles.categoryList} data-hook="post__categories">
                <CategoryLabelList post={post} />
              </div>
            )}
            <PostMainActions
              post={post}
              commentsLink={postLink && `${postLink}`}
              showShareButtons={showShareButtons}
              getPostClassName={getPostClassName}
              layoutName={layoutName}
            />
          </div>
        </div>
      </article>
    );
  }
}

Post.propTypes = {
  applyFeedDesign: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  getPostClassName: PropTypes.func,
  isDesktop: PropTypes.bool,
  isInPostPage: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  isCategoryLabelsEnabled: PropTypes.bool.isRequired,
  itemConfig: PropTypes.object,
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string,
  showMoreButton: PropTypes.bool,
  showShareButtons: PropTypes.bool.isRequired,
  showPostUpdatedDate: PropTypes.bool,
  showPostTitle: PropTypes.bool,
  t: PropTypes.func,
  titleFontClassName: PropTypes.string.isRequired,
  layoutName: PropTypes.string,
  showMoreOptionsMenu: PropTypes.bool,
  isMoreButtonEnabled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isTagsEnabled: PropTypes.bool,
};

Post.defaultProps = {
  isMetadataHeaderVisible: true,
};

const mapRuntimeToProps = (state, { isInPostPage, post, canSee }) => {
  const section = getSection(state) || SECTION_HOMEPAGE;
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const isPostInPreview = getIsPostInPreview(state);
  const showMoreButton = !isPostInPreview && Boolean(postActions.length);

  return {
    postLink: isInPostPage ? null : `/${post.slug}`,
    showMoreButton,
    showShareButtons: !isPostInPreview && includes(postActions, POST_ACTION_SHARE),
    layoutName: getLayoutName(getLayoutType(state, section)),
    isDesktop: getIsDesktop(state),
    isCategoryLabelsEnabled: getIsCategoryLabelsEnabled(state),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    isEditor: isEditor(state),
    isTagsEnabled: getIsTagsEnabled(state),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  connect(mapRuntimeToProps),
  withFontClassName,
  withTranslate,
  withFeedMetadataSettings,
  withPostBorderWidth,
  withIsFeedDesignEnabled,
)(Post);
